<!-- UserModal.vue -->
<template>
	<b-modal
		v-if="orderId"
		v-model="modalVisible"
		no-close-on-esc
		no-close-on-backdrop
		:hide-footer="verificationStatus === 'valid'"
		size="lg"
		:ok-title="translate('update')"
		:ok-disabled="orderUpdate.data.loading"
		:title="translate('order_id', { order_id: orderId })"
		@close="$emit('close')"
		@cancel="$emit('close')"
		@ok="update">
		<div>
			<b-row>
				<b-col cols="8">
					<p>{{ translate('id_number') }} : {{ idNumber }}</p>
					<p>{{ translate('recipients_name') }} : {{ shipTo }}</p>
				</b-col>
			</b-row>
			<b-row v-if="userImageFront && userImageBack">
				<b-col cols="6">
					{{ translate(`nin_${countryCode.toLowerCase()}_front`) }}
					<template v-if="verificationStatus !== 'valid'">
						<b-form-file
							id="personal_document_front_file"
							v-model="newFrontFile"
							:placeholder="translate('no_file')"
							:browse-text="translate('browse')"
							:accept="IMAGES_EXT.map((ext) => `.${ext}`).join(', ')"
							class="text-truncate"
							type="file" />
						<b-form-text id="personal_document_front_file">
							{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
						</b-form-text>
					</template>
					<br>
					<img
						width="100%"
						:src="userImageFront"
						@click="showImageViewer = true">
				</b-col>
				<b-col cols="6">
					{{ translate(`nin_${countryCode.toLowerCase()}_back`) }}
					<template v-if="verificationStatus !== 'valid'">
						<b-form-file
							id="personal_document_back_file"
							v-model="newBackFile"
							:placeholder="translate('no_file')"
							:browse-text="translate('browse')"
							:accept="IMAGES_EXT.map((ext) => `.${ext}`).join(', ')"
							class="text-truncate"
							type="file" />
						<b-form-text id="personal_document_back_file">
							{{ translate('max', {size: '5'}) }} {{ translate('allowed_ext', {ext: IMAGES_EXT }) }}
						</b-form-text>
					</template>
					<br>
					<img
						width="100%"
						:src="userImageBack">
					<image-viewer
						v-model="showImageViewer"
						:image-src="userImageBack" />
				</b-col>
			</b-row>
			<is-loading
				:loading-label="translate('loading')"
				:no-data-label="translate('data_not_found')"
				:loading="!(userImageBack && userImageFront)" />
		</div>
	</b-modal>
</template>

<script>
import Order from '@/util/Order';
import OrderVerification from '@/mixins/OrderVerification';
import {
	OrderVerifications, Users,
	Grids, Tooltip,
	Addresses, NIN,
} from '@/translations';
import ImageViewer from '@/components/ImageViewer/index.vue';
import { IMAGES_EXT } from '@/settings/Media';

const base64Encode = (data) => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(data);
	reader.onload = () => resolve(reader.result);
	reader.onerror = (error) => reject(error);
});

export default {
	name: 'UpdateOrderDocumentModal',
	components: { ImageViewer },
	messages: [
		OrderVerifications, Users, Grids, Tooltip,
		Addresses, NIN,
	],
	mixins: [OrderVerification],
	props: {
		userData: {
			type: Object,
			default: null,
		},
		orderId: {
			type: String,
			default: null,
		},
		shipTo: {
			type: String,
			default: '',
		},
		idNumber: {
			type: String,
			default: '',
		},
		countryCode: {
			type: String,
			default: '',
		},
		verificationStatus: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			modalVisible: true,
			userImageFront: '',
			userImageBack: '',
			orderData: new Order(),
			orderUpdate: new Order(),
			showImageViewer: false,
			form: {},
			IMAGES_EXT,
			newFrontFile: null,
			newBackFile: null,
		};
	},
	computed: {
		loading() {
			return this.orderData.loading;
		},
	},
	watch: {
		newFrontFile(newImg, oldImg) {
			if (oldImg !== newImg && newImg) {
				base64Encode(newImg)
					.then((value) => {
						this.userImageFront = value;
					})
					.catch(() => {
						this.userImageFront = null;
					});
			}
		},
		newBackFile(newImg, oldImg) {
			if (oldImg !== newImg && newImg) {
				base64Encode(newImg)
					.then((value) => {
						this.userImageBack = value;
					})
					.catch(() => {
						this.userImageBack = null;
					});
			}
		},
	},
	mounted() {
		this.fetchUserImage('front');
		this.fetchUserImage('back');
	},
	methods: {
		update(event) {
			event.preventDefault();
			if (this.newFrontFile) {
				this.form.personal_document_front_file = this.newFrontFile;
			}
			if (this.newBackFile) {
				this.form.personal_document_back_file = this.newBackFile;
			}
			const payload = this.objectToFormData(this.form);

			this.orderUpdate.updateOrderDocument(this.orderId, payload).then((response) => {
				const { message } = response.response;
				this.alert.toast('success', message, { timer: 6000 });
				this.$emit('close');
				this.$emit('reload');
			}).catch((errors) => {
				const { messageError } = errors;
				this.alert.toast('error', messageError, { timer: 6000 });
				this.$emit('close');
			}).finally(() => {
				this.$emit('close');
			});
		},
		fetchUserImage(side) {
			this.orderData.getDocument(this.orderId, side).then(() => {
				const { response } = this.orderData.data;
				const url = window.URL.createObjectURL(response.data);

				if (side === 'back') {
					this.newBackFile = null; // response.data;
					this.userImageBack = url;
				} else {
					this.newFrontFile = null; // response.data;
					this.userImageFront = url;
				}
			});
		},
	},
};
</script>
